import './App.css';
import * as React from 'react';
import { Auth0Lock } from 'auth0-lock';
import Button from '@mui/material/Button';
const lock = new Auth0Lock('50mJbvWHpTXJgDUTjI8Zrc3M6MC4JwLH','saqibdev1.eu.auth0.com', 
  {
    auth: {
      redirect: false,
      sso: false
    },
    redirect: false
  }
  );

function App() {

  return (
    <div className="App">
      <header className="App-header">
      <Button variant='contained' onClick={() => lock.show()}>
        Login
      </Button>
      </header>
    </div>
  );
}

export default App;
